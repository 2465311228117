import { GET_PRODUCTS } from "./actionType";

let intialstate={
    products:[],
    cart:[],
    usee:""

}

const reducer=(state=intialstate,action)=>{
switch (action.type) {
    case GET_PRODUCTS:
        console.log(action);
       return {...state,products:action.data}

    default:
        return state;
       
}
}

export default reducer;