import logo from './logo.svg';
import './App.css';
import { useDispatch } from 'react-redux';
import { fetchProducts } from './redux/action';

function App() {

  const disptch=useDispatch()
  disptch(fetchProducts())
  return (
    <div className="App">
      
    </div>
  );
}

export default App;
