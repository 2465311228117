import { GET_PRODUCTS } from './actionType'

export function getprodtcts (data) {
  return { type: GET_PRODUCTS, data }
}

export function fetchProducts () {
  return (dispatch) => {

    fetch("https://dummyjson.com/products")
    .then(response => response.json())
    .then(response => dispatch(getprodtcts(response.products)));
  }
}

